import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

import Card from '../components/Card';

import pic1 from '../assets/images/event/slideshow/1.jpg';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>L' accompagnement</h2>
          <p>Nous soutenons des artistes et compagnies éemergentes</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">L'accompagnement professionnel</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue <b><Link to="/artistes#concerts">les artistes</Link></b>. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>

          <h3 className="major">Artistes accompagnés</h3>
          <section className="features">
            
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            

          </section>
          
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
